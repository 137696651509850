<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Sucursales</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn
            v-if="this.$hasPermision('sucursales.create')"
            dark
            color="#023145"
            width="140"
            class="float-right"
            @click="handleShowCreate"
          >
            Nuevo
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
            v-model="search_office"
            label="Buscar"
            hide-details
            prepend-inner-icon="mdi-magnify"
            solo
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn
            v-if="this.$hasPermision('sucursales.filters')"
            dark
            color="#023145"
            outlined
            width="140"
            class="float-right"
            @click="handleShowFilters"
          >
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-model="selectedRows"
              :headers="headers"
              :items="offices"
              :items-per-page="10"
              class="elevation-1 w-table"
              height="57vh"
              show-select
              :single-select="true"
              @item-selected="handleSelectItem"
              @toggle-select-all="handleSelectAll"
            >
              <template v-slot:top>
                <span
                  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                >
                  Todas las sucursales
                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>
                <span v-if="item.active" class="activo">
                  <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                  activo
                </span>
                <span v-else class="inactivo">
                  <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                  inactivo
                </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <div class="text-center">
      <v-bottom-sheet
        v-model="show_options_crud"
        :hide-overlay="true"
        persistent
        :retain-focus="false"
      >
        <v-sheet class="text-center" height="85px">
          <div class="py-3">
            <v-btn
              v-if="this.$hasPermision('sucursales.edit')"
              class="mt-3"
              dark
              color="blue"
              outlined
              @click="handleEdit"
            >
              <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
              Editar
            </v-btn>
            <v-btn
              v-if="
                show_btn_active &&
                  this.$hasPermision('sucursales.change_status')
              "
              class="ml-4 mt-3"
              dark
              color="green"
              outlined
              @click="handleChangeStatus"
            >
              <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
              Activar
            </v-btn>
            <v-btn
              v-if="
                !show_btn_active &&
                  this.$hasPermision('sucursales.change_status')
              "
              class="ml-4 mt-3"
              dark
              color="amber"
              outlined
              @click="handleChangeStatus"
            >
              <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
              Inactivar
            </v-btn>
            <v-btn
              v-if="this.$hasPermision('sucursales.destroy')"
              class="ml-4 mt-3"
              dark
              color="red"
              outlined
              @click="handleDelete"
            >
              <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
              Eliminar
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <create-update ref="createUpdate"></create-update>
    <Filters ref="filters"></Filters>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import ArrayTools from "../../../helpers/ArrayTools";
import CreateUpdate from "../../../components/offices/CreateUpdate";
import Filters from "../../../components/offices/Filters";

export default {
  name: "Offices",
  components: {
    CreateUpdate,
    Filters,
    OfficeLogin,
  },
  data() {
    return {
      search_office: null,
      drawer: false,
      show_options_crud: false,
      show_btn_active: false,
      selectedRows: [],
      itemsBreadcrumbs: [
        {
          text: "Configuración",
          disabled: true,
          href: "",
        },
      ],
      headers: [
        { text: "SUCURSAL", value: "name" },
        { text: "TELÉFONO", value: "phone" },
        { text: "DIRECCIÓN", value: "address" },
      ],
    };
  },
  computed: {
    ...mapState("offices", ["offices"]),
  },
  methods: {
    ...mapActions("companies", ["getCompanies"]),
    ...mapActions("offices", ["getOffices", "change_status", "delete"]),
    showOptionCrud() {
      this.show_options_crud = this.selectedRows.length > 0;
      if (this.show_options_crud) {
        this.show_btn_active = !this.selectedRows[0].active;
      }
    },
    handleSelectItem(selected) {
      if (selected.value) {
        this.selectedRows.push(selected.item);
      } else {
        let index_found = ArrayTools.getIndexById(
          this.selectedRows,
          selected.item.id
        );
        if (index_found > -1) {
          this.selectedRows.splice(index_found, 1);
        }
      }
      this.showOptionCrud();
    },
    handleSelectAll(selected) {
      if (selected.value) {
        this.selectedRows = selected.items;
      } else {
        this.selectedRows = [];
      }
      this.showOptionCrud();
    },
    handleShowCreate() {
      this.$refs.createUpdate.showForm("Nueva Sucursal", "create");
    },
    handleEdit() {
      let office = this.selectedRows[0];
      this.selectedRows = [];
      this.showOptionCrud();
      this.$refs.createUpdate.showForm(
        "Modificar datos de sucursal",
        "update",
        office
      );
    },
    handleShowFilters() {
      this.$refs.filters.showForm("Filtrar datos de sucursales");
    },
    async getAllOffices() {
      await this.getOffices();
    },
    async handleSearch() {
      this.selectedRows = [];
      this.showOptionCrud();
      let filters = {
        name: this.search_office,
      };
      await this.getOffices(filters);
    },
    async handleChangeStatus() {
      let office = this.selectedRows[0];
      this.selectedRows = [];
      this.showOptionCrud();
      await this.change_status(office);
    },
    async handleDelete() {
      let office = this.selectedRows[0];
      await this.delete(office);
      this.selectedRows = [];
      this.showOptionCrud();
    },
  },
  created() {
    this.getCompanies();
    this.getAllOffices();
  },
};
</script>

<style></style>
