<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="filters.name" label="Nombre" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="filters.phone" label="Teléfono" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>
                <!--
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.sede_id"
                            :items="sedes"
                            label="Seleccione sede"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                -->                 
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'Filters',
    data() {
        return {
            drawer: false,
            titleForm: null,
            filters: {
                name: null,
                sede_id: null,
                phone: null,
                company_id: null
            }
        }
    },
    computed: {
        ...mapState('companies', [
            'companies'
        ]),
        ...mapState('sedes', [
            'sedes'
        ])
    },
    methods: {
        ...mapActions('sedes', [
            'getSedes'
        ]),
        ...mapActions('offices', [
            'getOffices'
        ]),
        showForm(title) {
            this.titleForm = title
            this.selectCompany();
            this.drawer = !this.drawer
        },
        closeForm() {
            this.drawer = false
        },
        clearData() {
            this.filters.name = null
            this.filters.sede_id = null
            this.filters.phone = null
            this.filters.company_id = null;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        async handleClearFilters() {
            this.clearData()
            await this.getOffices(this.filters)
        },
        async handleSearch() {
            await this.getOffices(this.filters)
            this.closeForm()
        },
        selectCompany () {
            if (this.companies.length == 1) {
                this.filters.company_id = this.companies[0].id;
            }
        }
    },
    mounted() {
        this.getSedes()
    }
}
</script>

<style scoped>

</style>